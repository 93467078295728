@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.downloadProgressBar {
  .ui.progress {
    margin-top: 2px;
    overflow: hidden;

    .bar {
      background: $bcg-green--blue-green;
      min-width: 0 !important;
    }
  }

  &.error {
    .ui.progress {
      .bar {
        background: $bcg-red;
      }
    }
  }
}
