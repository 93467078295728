@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.ui.button {
  background: $white;
  border: 2px solid $bcg-green--blue-green;
  color: $bcg-green--blue-green;
  border-radius: 5px;
  text-transform: uppercase;
  padding: 0.25em 1em;

  font-family: $sans-bold;
  font-size: 10px;
  line-height: 1.5;
  letter-spacing: 1.5px;

  &.searchTerm {
    border: 0;
    padding-left: 4px;
    background: $transparent;
    color: $bcg-green--blue-green;

    &.searchTerm:hover {
      cursor: default;
      color: $bcg-green--blue-green;
    }

    &.accept {
      color: $black;

      &:hover {
        color: $black;
        background: $transparent;
      }
    }

    &.reject:hover {
      text-decoration: underline;
      color: $bcg-green--blue-green;
      background: $transparent;
      cursor: pointer;
    }
  }

  &.documentActions-mobile__btn {
    border: 0;
  }

  .icon {
    height: auto;
  }

  &:hover {
    border-color: $bcg-green--dark;
    color: $bcg-green--dark;
    background: $white;

    .icon {
      opacity: 1;
    }
  }

  &:disabled {
    border-color: $gray--darker;
    color: $gray--darker;
  }

  &.invert {
    background: $bcg-green--blue-green;
    color: $white;

    &:hover {
      background: $bcg-green--dark;
      border-color: $bcg-green--dark;
    }

    &:disabled {
      background: $gray--darker;
      border-color: $gray--darker;
    }
  }

  &.maintenance {
    height: auto;
    margin-left: 48px;
  }

  &.action {
    margin: 0 0 0 8px;
    cursor: pointer;
    position: relative;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 0;
    background: $transparent;
    outline: 0;
    padding: 0;
  }
}