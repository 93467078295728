@import 'src/global-styles/colors';

.ui.progress {
  background: $gray--darker;
  
  &:first-child {
    margin: 0;
  }

  .bar {
    background: $gray--dark;
  }
}