// Primary brand colors
$bcg-green--dark: #00532F;
$bcg-green: #177B57;
$bcg-green--blue-green: #3EAD93;
$bcg-green--blue: #3FAD93;
$bcg-green--light: #32C77F;
$bcg-yellow: #E4EF39;
$bcg-red: #D50000;
$bcg-blue: #00BCD4;
$bcg-blue--light: #88C0FE;
$bcg-blue--dark: #1E455E;
$bcg-orange: #FF7002;

// Grayscale
$black: #000000;
$gray--darkest: #333333;
$gray--darker: #707070;
$gray--dark: #B7B7B7;
$gray--dark--light: #D8D8D8;
$gray: #E6E6E6;
$gray2: #BEBEBE;
$gray--light: #F2F2F2;
$gray--lighter: #FAFBFB;
$gray--lightest: #FAFAFA;
$white: #FFFFFF;
$tab-menu: #F5F5F5;
$transparent: rgba(0, 0, 0, 0); // transparent convers to initial, rgba(0,0,0,0) translates to transparent.......... compilers these days
$checkboxgray: #979797;

//notifications
$notify-error: #FAE6E6;
$notify-error-highlight: $bcg-red;
$notify-info: #EAF7F9;
$notify-info-highlight: $bcg-red;
$notify-success: #EDF5D7;
$notify-success-highlight: #4CAF50;
$notify-warn: #FFF6D0;
$notify-warn-highlight: #FF9800;

//rating
$rating-absent: #ECE3E3;
$rating-present: #F8CE0B;

//modal-color 
$modal-white: rgba($white, 0.95);

$very-dark-gray-mostly-black: #111111;
$light-gray: #D4D4D4;
$light-gray2: #CACACA;
$very-light-gray: #DDDDDD;
$very-light-gray2: #D9D9D9;
$very-light-gray-mostly-white: #F8F8F8;
$very-light-gray-mostly-white2: #F4F4F4;

$dark-moderate-cyan: $bcg-green--blue;
$dark-moderate-cyan2: #3FA0AD;
$very-soft-lime-green: #A8F0B8;
$moderate-cyan--lime-green: #56C989;
$moderate-cyan--lime-green2: #4FBE79;
$strong-cyan--lime-green: #21BF61;
$very-dark-cyan--lime-green: #0E3E1B;
$pure-or-mostly-pure-yellow: #FFFF00;

/* Primary/Green200 */
$light-grayish-lime-green: #DCF9E3;

$bright-yellow: #F1FE39;
$gvgray: #323232;
$gvreadmore: #6E6E73;
$gvbutton: $very-dark-gray-mostly-black;

$closeicon: #737373;
$careticon: #B1B1B1;
$border: $light-gray;
$darkgreen: #197A56;

$darkertan: #C4B5A4;
$darktan: #DFD7CD;
$tan: #856E57;
$lighttan: #F1EEEA;

$yellow: #FFE68F;
$lighttext: #535353;
$filtercount: $light-grayish-lime-green;
$babyblue: #E0F1FF;

//shadow details
%shadow--1 {
  box-shadow: 0 1px 1px 0 rgba($black, 0.14), 0 2px 1px -1px rgba($black, 0.12), 0 1px 3px 0 rgba($black, 0.2);
}

%shadow--2 {
  box-shadow: 0 6px 10px 0 rgba($black, 0.14), 0 1px 18px 0 rgba($black, 0.12), 0 3px 5px -1px rgba($black, 0.2);
}

%shadow--3 {
  box-shadow: 0 2px 6px 0 #F2F2F2;
}

%shadow--4 {
  box-shadow: -3px 3px 11px 3px #B7B7B7;
}

%shadow--5 {
  box-shadow: 6px 3px 11px 3px #B7B7B7;
}

%shadow--6 {
  box-shadow: 0 2px 4px 0 rgba(51, 51, 51, 0.4);
}

%shadow--7 {
  box-shadow: 0 0 20px 0 #F2F2F2;
}

%shadow--8 {
  box-shadow: 0 9px 11px 3px #B7B7B7;
}

%shadow--9 {
  box-shadow: 0 0 3px 0 #707070;
}

%shadow--10 {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}