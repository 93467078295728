@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.carousel {
  background-color: $white;
  overflow: hidden;
  height: 144px;
  border: 0;
  padding: 0 36px;
  position: relative;

  @media (max-width: map-get($grid-breakpoints, lg)) {
    position: relative;
    height: 188px;
  }

  &.empty {
    height: auto;
  }

  .scroller {
    overflow: auto;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    z-index: 1;
    margin-right: 10px;
    height: 131px;
    border-right: 2px solid $gray--dark;
    border-left: 2px solid $gray--dark;

    &::-webkit-scrollbar {
      width: 0 !important;
      height: 0 !important;
    }

    @media (max-width: map-get($grid-breakpoints, lg)) {
      display: flex;
      height: 179px;
    }
  }

  .hideBorderLeft {
    border-left: 0;
  }

  .hideBorderRight {
    border-right: 0;
  }

  .hideArrow {
    display: none;
  }

  .arrow_container {
    display: block;

    .leftArrow,
    .rightArrow {
      position: absolute;
      z-index: 15;
      width: 75px;
      background: none;
      min-height: 127px;
      height: 100%;
      border: 0;
      border-radius: 0;
      color: $gray--darker;
      font-size: 19px;

      &:hover,
      &:active,
      &:focus {
        background: none;
      }

      @media (max-width: map-get($grid-breakpoints, lg)) {
        height: 175px;
      }

    }

    .leftArrow {
      left: 0;
      text-align: left;

      &:hover,
      &:active,
      &:focus {
        background-image: linear-gradient(to right, $white, rgba(255, 255, 255, 0));
      }
    }

    .rightArrow {
      right: 0;
      text-align: right;

      &:hover,
      &:active,
      &:focus {
        background-image: linear-gradient(to left, $white, rgba(255, 255, 255, 0));
      }
    }
  }

  .disableArrows {
    cursor: default;

    i {
      color: $gray;
    }
  }
}

.topRightArrows {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    float: right;
    position: absolute;
    right: 92px;
  }
}

.grid {
  display: grid;
}