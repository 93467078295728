@import 'src/global-styles/colors';

.Toastify {
  .Toastify__toast-container {
    width: 445px;
  }

  .Toastify__toast {
    padding: 15px;

    i {
      font-size: 1.3em;
    }
  }

  h4 {
    font-size: 18px;
    color: $black;
    font-family: HendersonBCGSans, Arial, Helvetica, sans-serif;
    display: inline;
    margin-left: 10px;
  }

  .message {
    display: inline-block;
    color: $gray--darker;
    font-size: 14px;
    font-family: HendersonBCGSans, Arial, Helvetica, sans-serif;
    padding-left: 35px;
  }

  .Toastify__toast--error {
    background-color: $notify-error;
    border-left: 4px solid $notify-error-highlight;

    i,
    .Toastify__close-button {
      color: $notify-error-highlight;
    }
  }

  .Toastify__toast--info {
    background-color: $notify-info;
    border-left: 4px solid $notify-info-highlight;

    i,
    .Toastify__close-button {
      color: $notify-info-highlight;
    }
  }

  .Toastify__toast--success {
    background-color: $notify-success;
    border-left: 4px solid $notify-success-highlight;

    i,
    .Toastify__close-button {
      color: $notify-success-highlight;
    }
  }

  .Toastify__toast--warning {
    background-color: $notify-warn;
    border-left: 4px solid $notify-warn-highlight;

    i,
    .Toastify__close-button {
      color: $notify-warn-highlight;
    }
  }
}