@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.progressBarMain {
  box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
    0 16px 24px 2px rgba(0, 0, 0, 0.14);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  max-width: 390px;

  .modalHeadingBar {
    background-color: $bcg-green--blue-green;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;

    .modalHeader {
      font-family: $sans-bold;
      font-size: 14px;
      color: $white;
      padding: 7px 4px;
      float: left;
    }
  
    .closeModal {
      cursor: pointer;
      float: right;
      margin-right: 5px;
      color: $white;
      padding: 7px 0;
    }
  }
  
  .progressBarsContainers {
    background: $white;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;

    .attachmentInfo {
      background: $white;
      padding-top: 5px;
      padding-bottom: 5px;

      &:first-child {
        padding-top: 15px;
      }
      
      .attachName {
        position: relative;
        font-size: 11px;
        font-family: $sans-reg;
        color: $gray--darker;
        text-align: left;
        padding-left: 5px;
        margin-bottom: 5px;
        white-space: nowrap;
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
 
      &:last-child {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
      }

      .ui.progress .bar {
        background: $bcg-green--blue-green;
      }

      .bar {
        top: 25%;
      }

      .percentage {
        font-size: 9px;
        font-family: $sans-bold;
        color: $bcg-green--blue-green;
        position: relative;
        right: 5px;
      }
    }
  }
}
