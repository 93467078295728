#loading {
  .loading-header {
    position: relative;
    background-size: cover !important;
    background-position: center !important;
    width: 100%;
    min-height: 320px;
    padding-top: 16px;
    margin: 0;
    margin-bottom: 50px;
  }

  .loading-wrapper {
    width: 100%;
    text-align: center;
  }
}