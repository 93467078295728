@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.progressBarContainer {
  z-index: 2120 !important;
  position: fixed;
  bottom: 16px;
  right: 17px;
  text-align: center;
  border-radius: 7px;

  .progressButton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    border-radius: 7px;
    background-color: $bcg-green--blue-green;
    box-shadow: 0 8px 10px -5px rgba(0, 0, 0, 0.2), 0 6px 30px 5px rgba(0, 0, 0, 0.12),
      0 16px 24px 2px rgba(0, 0, 0, 0.14);
    padding: 15px;
    cursor: pointer;
    transform: translateX(-100%);
    animation: slide-in 0.5s forwards;

    @keyframes slide-in {
      100% {
        transform: translateX(0%);
      }
    }
  }

  .downloadText {
    font-family: $sans-reg;
    color: $white;
    font-size: 10px;
    padding-top: 5px;
  }
}