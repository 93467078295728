@import 'src/global-styles/responsive';
@import 'src/global-styles/colors';

.layout {
  // Sticky footer styles
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  background-color: $gray--light;
  //End sticky footer styles

  #main {
    /* IE11+ don't support <main> */
    width: 100%;
    flex: 1 1 auto;
    min-height: 1250px;

    &.nofooter {
      min-height: auto;
    }
  }
}